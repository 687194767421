export const portfolioList = [
  {
    id: "featured",
    title: "Featured",
  },
  {
    id: "reactjs",
    title: "ReactJS & Firebase",
  },
  {
    id: "frontend",
    title: "Front-End",
  },
];

export const featuredPortfolio = [
  {
    id: 1,
    title: "Full Ecommerce Store",
    img: "https://i.imgur.com/DyJFm9S.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/ECOMMERCE-store.git",
    liveUrl: "https://eshop-68b6c.web.app/home",
    tagline: "ReactJS,Redux,Routing & Firebase backend",
  },
  {
    id: 2,
    title: "Movies App",
    img: "https://i.imgur.com/FReHKta.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/flix-movie-site.git",
    liveUrl: "https://kaleidoscopic-cheesecake-36b1a8.netlify.app/",
    tagline: "HTML,CSS & Javascript",
  },
  {
    id: 3,
    title: "Shoe Store",
    img: "https://i.imgur.com/wDq01kw.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/shoe-app.git",
    liveUrl: "https://vibrant-clarke-1195aa.netlify.app/",
    tagline: "HTML,CSS & Javascript",
  },
  {
    id: 4,
    title: "Quotes App with Backend",
    img: "https://i.imgur.com/2ureIgV.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/Quotes-app.git",
    liveUrl: "https://quotesapp-52fd5.web.app/quotes",
    tagline: "ReactJS,Redux,Routing and Firebase Backend",
  },
];

export const frontendPortfolio = [
  {
    id: 1,
    title: "Shoe Store",
    img: "https://i.imgur.com/wDq01kw.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/shoe-app.git",
    liveUrl: "https://vibrant-clarke-1195aa.netlify.app/",
    tagline: "HTML,CSS & Javascript",
  },
  {
    id: 2,
    title: "Figma TO Web",
    img: "https://i.imgur.com/i6dEbQr.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/figma-to-html",
    liveUrl: "https://incandescent-sherbet-a0dee7.netlify.app/",
    tagline: "HTML,CSS & Javascript",
  },
  {
    id: 2,
    title: "Movies App",
    img: "https://i.imgur.com/FReHKta.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/flix-movie-site.git",
    liveUrl: "https://kaleidoscopic-cheesecake-36b1a8.netlify.app/",
    tagline: "HTML,CSS & Javascript",
  },
  {
    id: 4,
    title: "Nike Store Landing Page",
    img: "https://i.imgur.com/O7FRvDf.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/Nike-shoes-app.git",
    liveUrl: "https://stunning-sundae-ccc34a.netlify.app/",
    tagline: "HTML,CSS & Javascript",
  },
];

export const reactjsPortfolio = [
  {
    id: 1,
    title: "Online Food Order App",
    img: "https://i.imgur.com/HfIEbmc.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/Food-app.git",
    liveUrl: "https://foodorder-app-99cd2.web.app/",
    tagline: "ReactJS, Redux & Firebase",
  },
  {
    id: 2,
    title: "Full Ecommerce Store",
    img: "https://i.imgur.com/DyJFm9S.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/ECOMMERCE-store.git",
    liveUrl: "https://eshop-68b6c.web.app/home",
    tagline: "ReactJS,Redux,Routing & Firebase backend",
  },
  {
    id: 3,
    title: "Quotes App with Backend",
    img: "https://i.imgur.com/2ureIgV.jpg",
    repositoryUrl: "https://github.com/ashardeveloper/Quotes-app.git",
    liveUrl: "https://quotesapp-52fd5.web.app/quotes",
    tagline: "ReactJS,Redux,Routing and Firebase Backend",
  },
];

// export const web3Portfolio = [
//   {
//     id: 1,
//     title: "React Web3 App",
//     img: "https://i.imgur.com/OxClJvP.png",
//     repositoryUrl: "https://github.com/developer-junaid/React-NFT-App",
//     liveUrl: "https://react-nft-project.netlify.app/",
//     tagline: "Web3 / NFT Listing",
//   },
//   {
//     id: 2,
//     title: "TransferKrypt",
//     img: "https://i.imgur.com/K4GroFa.png",
//     repositoryUrl: "https://github.com/developer-junaid/Transfer-Krypt",
//     liveUrl: "https://transferkrypt.netlify.app/",
//     tagline: "Transfer Crypto / Blockchain",
//   },
// ];

// // Testimonials
// export const fiverrTestimonials = [
//   {
//     id: 1,
//     text: "Great communication skills, very good english and easy to work with! thanks for the excellent work and I hope we will work together in the future again!",
//     country: "Italy",
//     name: "Leo Matter",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 2,
//     text: "Junaid represents among the highest levels of customer service I have experienced. Information was accurate, responses to queries were turned around very fast. Answers were clear and where necessary detailed enough for us to make informed decisions quickly. Thanks so much we will be working with you in future.",
//     country: "Pakistan",
//     name: "Arslan",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 3,
//     text: "Very professional and know what he is doing, will be working with you again in the future!",
//     country: "Belgium",
//     name: "Walid",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 4,
//     text: "this developer is the one of a kind with great communication skills!",
//     country: "Denmark",
//     name: "dgaa1991",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 5,
//     text: "Perfect man for your job",
//     country: "Cyprus",
//     name: "worldwidefame",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 6,
//     text: "From my very first message to last, the seller communicated everything clearly and asked all the right questions unlike others asking for budget. The code is very clean (easy to maintain and good commenting). Very quick delivery, ill be ordering again soon. Thanks again!!",
//     country: "UK",
//     name: "Ahmed",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 7,
//     text: "2nd order done already, one of the quickest delivery on Fiverr. Again, task was done alot better than expected, thank you!",
//     country: "UK",
//     name: "Ahmed",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 8,
//     text: "Great service, fast and responsive!",
//     country: "Belgium",
//     name: "Walid",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
//   {
//     id: 9,
//     text: "good work, thank you",
//     country: "Belgium",
//     name: "Walid",
//     url: "https://www.fiverr.com/developerjunaid/",
//   },
// ];
