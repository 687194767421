import classes from "./About.module.css";
import Award from "../../img/award.png";

const About = () => {
  return (
    <div className={classes.a} id="about">
      <div className={classes["a-left"]}>
        <div className={`${classes["a-card"]} ${classes["bg"]}`}></div>
        <div className={classes["a-card"]}>
          <img
            src="https://images.pexels.com/photos/3861959/pexels-photo-3861959.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
            alt=""
            className={classes["a-img"]}
          />
        </div>
      </div>
      <div className={classes["a-right"]}>
        <h1 className={classes["a-title"]}>About Me</h1>
        <p className={classes["a-sub"]}>Hi, Nice to meet you.</p>
        <p className={classes["a-desc"]}>
          I'm ashar, A passoionate Front End Developer having an experience of
          building Web applications with HTML/CSS/Javascript/Reactjs and some
          other cool libraries. My core is Reactjs Development. I have developed
          advanced react apps using react hooks, redux and react routing.
        </p>
        {/* <div className={classes["a-award"]}>
          <img src={Award} alt="" className={classes["a-award-img"]} />
          <div className={classes["a-award-texts"]}>
            <h4 className={classes["a-award-title"]}>
              International Design Awards 2021
            </h4>
            <p className={classes["a-award-desc"]}>
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
              and fugit.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
