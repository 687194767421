import React, { useEffect, useState } from "react";

// Styles
import "./portfolio.css";

// Data
import {
  portfolioList,
  featuredPortfolio,
  frontendPortfolio,
  reactjsPortfolio,
} from "../../data/data";

// components
import { Heading } from "../Heading/Heading";
import PortfolioItem from "./PortfolioItem/PortfolioItem";

// Icons
import { FaGithubSquare } from "react-icons/fa";
import { BsArrowUpRightSquareFill } from "react-icons/bs";

const Portfolio = () => {
  const [selected, setSelected] = useState("featured");
  const [data, setData] = useState([]);

  useEffect(() => {
    switch (selected) {
      case "featured":
        setData(featuredPortfolio);
        break;

      case "frontend":
        setData(frontendPortfolio);
        break;

      case "reactjs":
        setData(reactjsPortfolio);
        break;

      default:
        break;
    }
  }, [selected]);

  return (
    <section
      data-aos="fade-left"
      className="portfolio"
      name="portfolio"
      id="portfolio"
    >
      {/* style={{ padding: "3rem" }} */}
      <Heading text="Portfolio" />
      <div className="list">
        {portfolioList &&
          portfolioList.map((list) => (
            <PortfolioItem
              title={list.title}
              key={list.id}
              active={selected === list.id}
              setSelected={setSelected}
              id={list.id}
              repoUrl={list.repositoryUrl}
              liveUrl={list.liveUrl}
              tagline={list.tagline}
            />
          ))}
      </div>
      <div className="row">
        {data &&
          data.map((item, index) => (
            <div className="column" key={index}>
              <img src={item.img} alt={item.title} />
              <div className="overlay">
                <div className="left">
                  <h3>{item.title}</h3>
                  {item.tagline && <p>{item.tagline}</p>}
                </div>
                <div className="right">
                  <a href={item.repositoryUrl} target="_blank">
                    <FaGithubSquare
                      className="icon"
                      style={{ marginRight: "0.6em", fontSize: "40px" }}
                      title="Github Repo"
                    />
                  </a>

                  <a
                    href={item.liveUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsArrowUpRightSquareFill
                      style={{ marginRight: "0.6em", fontSize: "35px" }}
                      className="icon"
                      title="Live view"
                    />
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>
    </section>
  );
};

export default Portfolio;
