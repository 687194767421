import { useContext, useState } from "react";
import Topbar from "./components/topbar/Topbar";
import Toggle from "./components/toggle/Toggle";
import Intro from "./components/intro/Intro";
import About from "./components/about/About";
import ProductList from "./components/productList/ProductList";
import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/contact/Contact";
import { ThemeContext } from "./context";
import "./app.scss";
import Menu from "./components/menu/Menu";
import Skills from "./components/skills/Skills";

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div
      className="app"
      style={{
        backgroundColor: darkMode ? "#222" : "white",
        color: darkMode && "white",
      }}
    >
      <Topbar menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <Menu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
      <div className="sections">
        <Toggle />
        <Intro />
        <About />
        {/* <ProductList /> */}
        <Portfolio />
        <Skills />
        <Contact />
      </div>
    </div>
  );
};

export default App;
