import classes from "./Skills.module.css";

const Skills = () => {
  return (
    <div className={classes.a} id="skills">
      <h2 className={classes["a-left"]}>
        <span className={classes.skillsTitle}>SKILLS</span>
      </h2>
      <div className={classes["a-right"]}>
        <p className={classes["a-title"]}>
          Looking for Highly skilled Front End Developer? You are on the right
          place.
        </p>
        <div className={classes.skills}>
          <div className={classes.skillFirstItem}>HTML</div>
          <div className={classes.skillItem}>CSS</div>
          <div className={classes.skillItem}>JAVASCRIPT</div>
          <div className={classes.skillItem}>REACTJS</div>
          <div className={classes.skillItem}>FIREBASE</div>
          <div className={classes.skillItem}>GIT</div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
